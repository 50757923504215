<template>
  <div
    class="news"
    v-scrollintoview="'News'"
  >
    <div class="view-container">
      <news-item
        class="news-item-mrgin"
        v-for="(item, index) in itemData"
        :item-data="item"
        :key="index"
        @click.native="newPage(item)"
      ></news-item>
      <load-more
        v-if="!hideLoad"
        @clickLoad="clickLoad"
        :status="listStatus"
      ></load-more>
    </div>
  </div>
</template>

<script>
import LoadMore from 'components/news/LoadMore'
import api from '@/api/index'
import { toLink } from 'utils/utils'
import { mapGetters } from 'vuex'
export default {
  components: { LoadMore },
  data () {
    return {
      listStatus: 'more',
      hideLoad: false,
      itemData: [],
      pageNum: 1,
      pageSize: 10
    }
  },
  computed: {
    ...mapGetters(['lang'])
  },
  watch: {
    lang () {
      this.itemData = []
      this.pageNum = 1
      this.clickLoad()
    }
  },
  created () {
    this.clickLoad()
  },
  methods: {
    newPage ({ newsNum }) {
      const routeUrl = this.$router.resolve({
        name: 'NewsDetail',
        query: {
          newsNum,
          ...this.$route.query
        }
      })
      toLink(routeUrl.href)
    },
    clickLoad () {
      this.listStatus = 'load'
      const { pageNum, pageSize } = this
      api.getNewsList({
        pageNum,
        pageSize
      }).then((res) => {
        res = res || []
        if (pageNum === 1) {
          this.itemData = res
        } else {
          this.itemData = [
            ...this.itemData,
            ...res
          ]
        }
        this.pageNum = pageNum + 1
        if (res.length < pageSize) {
          this.hideLoad = true
        }
        this.listStatus = 'more'
      }).catch(() => {
        this.listStatus = 'more'
      })
    }
  }
}
</script>

<style scoped lang="less">
.news {
  min-height: 1000px;
  background: #f7f8fa;
  padding-top: 98px;
  padding-bottom: 229px;
  .news-item-mrgin {
    margin-bottom: 20px;
  }
}
@media screen and (max-width: @mobileWidth) {
  .news {
    min-height: 500px;
    background: #f7f8fa;
    padding-top: 30px;
    padding-bottom: 80px;
    .news-item-mrgin {
      margin-bottom: 15px;
    }
  }
}
</style>
